import classNamesFn from 'classnames';
import React, { ComponentType } from 'react';
import { MeshContainerProps } from '@wix/thunderbolt-components';
import {
  inlinePopupSkinProps,
  MenuContainerProps,
} from '../../MenuContainer.types';
import FillLayers from '../../../FillLayers/viewer/FillLayers';
import MeshContainer from '../../../../thunderbolt-core-components/MeshContainer/viewer/MeshContainer';
import styles from './DefaultInlinePopup.scss';

const DefaultInlinePopup: ComponentType<inlinePopupSkinProps & {
  fillLayers: MenuContainerProps['fillLayers'];
  meshProps?: MeshContainerProps;
}> = ({ classNames, layerIds, children, fillLayers, meshProps, id }) => {
  return (
    <React.Fragment>
      <div
        id={layerIds.overlay}
        className={classNamesFn(styles.overlay, {
          [styles.horizontallyDocked]: classNames.includes(
            'horizontallyDocked',
          ),
        })}
      />
      <div id={layerIds.container} className={styles.container}>
        <FillLayers {...fillLayers} />
        <div
          id={layerIds.inlineContentParent}
          className={classNamesFn(styles.inlineContentParent, {
            [styles.verticallyDocked]: classNames.includes('verticallyDocked'),
          })}
        >
          <MeshContainer id={id} {...meshProps} aria-label="navigation dialog">
            {children}
          </MeshContainer>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DefaultInlinePopup;
