import React, { useState } from 'react';
import classNamesFn from 'classnames';

import { useScrollPosition } from '../../../providers/useScrollPosition';
import { debounce } from '../../../core/commons/utils';
import { BackToTopButtonProps } from '../BackToTopButton.types';
import { TestIds } from '../constants';
import style from './style/BackToTopButton.scss';

const BACK_TO_TOP_DISTANCE = 512;
const BACK_TO_TOP_HIDE_DELAY = 3000;

const scrollToTop = () => {
  window.scroll({ top: 0, left: 0, behavior: 'smooth' });
};

const BackToTopButton: React.FC<BackToTopButtonProps> = ({
  id,
  svgContent,
}) => {
  const [showBackToTop, setShowBackToTop] = useState(false);

  const hide = debounce(() => {
    setShowBackToTop(false);
  }, BACK_TO_TOP_HIDE_DELAY);

  const onScroll = (pageYOffset: number) => {
    const isZoomed = false; // FIXME - how are we gonna handle runtime/client-only props? https://github.com/wix-private/santa/blob/3ec15fea98aaad88b399d62447deb7a18b0ee03a/packages/warmupUtils/src/main/siteData/MobileDeviceAnalyzer.js#L319
    if (pageYOffset > BACK_TO_TOP_DISTANCE && isZoomed === false) {
      setShowBackToTop(true);
      hide();
    }
  };

  useScrollPosition(({ currPos }) => onScroll(Math.abs(currPos.y)), [
    showBackToTop,
  ]);
  const classes = classNamesFn(style.root, {
    [style.visible]: showBackToTop,
  });
  return (
    <div
      id={id}
      className={classes}
      onClick={scrollToTop}
      data-testid={TestIds.BackToTopButtonRoot}
    >
      <div dangerouslySetInnerHTML={{ __html: svgContent }} />
    </div>
  );
};

export default BackToTopButton;
