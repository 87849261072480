import classNames from 'classnames';
import * as React from 'react';
import { MenuToggleProps } from '../MenuToggle.types';
import styles from './skinComps/inlinePopupToggleCommon.scss';
import { MenuToggleSkinMap } from './skinMap';

const MenuToggle: React.FC<MenuToggleProps> = ({
  id,
  skin = 'inlinePopupToggleSkin1',
  isOpen = false,
  onClick,
  onKeyDown,
}) => {
  const menuToggleRef = React.useRef<HTMLDivElement>(null);

  if (!isOpen) {
    menuToggleRef.current?.focus();
  }

  const MenuToggleContent = MenuToggleSkinMap[skin];

  return (
    <div
      id={id}
      className={classNames(styles.menuToggle, isOpen && styles.toggleOpen)}
      ref={menuToggleRef}
      role="button"
      aria-haspopup="dialog"
      aria-label={`${isOpen ? 'close' : 'open'} navigation dialog`}
      tabIndex={0}
      onClick={onClick}
      onKeyDown={onKeyDown}
    >
      <div
        className={classNames(
          styles.buttonContainer,
          isOpen && styles.toggleOpen,
        )}
      >
        <MenuToggleContent isOpen={isOpen} />
      </div>
    </div>
  );
};

export default MenuToggle;
