import * as React from 'react';
import classnames from 'classnames';
import Link from '../../Link/viewer/Link';
import { ExpandableMenuItemCompProps } from '../ExpandableMenu.types';
import { TestIds } from '../constants';
import style from './style/ExpandableMenuItem.scss';

type ItemAriaProps = {
  'aria-current'?: 'page';
};

type ToggleAriaProps = {
  'aria-expanded'?: boolean;
  'aria-haspopup'?: boolean;
};

type LinkWrapperProps = {
  label: ExpandableMenuItemCompProps['label'];
  link: ExpandableMenuItemCompProps['link'];
  onClick?: () => void;
};

const LinkWrapper: React.FC<LinkWrapperProps> = ({ label, link, onClick }) => {
  return (
    <span
      data-testid={TestIds.linkWrapper}
      className={style.labelWrapper}
      onClick={onClick}
    >
      <Link {...link} className={style.label}>
        {label}
      </Link>
    </span>
  );
};

const getItemAriaProps = (isCurrent: boolean): ItemAriaProps =>
  isCurrent
    ? {
        'aria-current': 'page',
      }
    : {};

const SingleItem: React.FC<ExpandableMenuItemCompProps> = ({
  label,
  link,
  skin,
  isSelected,
  isCurrent,
  idPrefix,
}) => {
  return (
    <li
      data-testid={idPrefix}
      {...getItemAriaProps(isCurrent)}
      className={classnames(style[skin], style.item, {
        [style.selected]: isSelected,
      })}
    >
      <div data-testid={TestIds.itemWrapper} className={style.itemWrapper}>
        <LinkWrapper label={label} link={link} />
      </div>
    </li>
  );
};

const SubMenuItem: React.FC<ExpandableMenuItemCompProps> = ({
  label,
  link,
  items = [],
  skin,
  isSelected,
  isCurrent,
  idPrefix,
}) => {
  const selectedSubItemIndex = items.findIndex(item => item.isSelected);

  const initialIsOpen = selectedSubItemIndex !== -1;
  const [showSubMenu, setShowSubMenu] = React.useState(initialIsOpen);

  React.useEffect(() => {
    const isOpen = selectedSubItemIndex !== -1;
    setShowSubMenu(isOpen);
  }, [selectedSubItemIndex]);

  const toggleSubMenu = () => setShowSubMenu(!showSubMenu);

  const toggleAriaProps: ToggleAriaProps = {
    'aria-expanded': showSubMenu,
    'aria-haspopup': true,
  };

  return (
    <li
      data-testid={idPrefix}
      {...getItemAriaProps(isCurrent)}
      className={classnames(style[skin], style.item, style.hasSubList, {
        [style.selected]: isSelected,
        [style.subMenuOpen]: showSubMenu,
      })}
    >
      <div data-testid={TestIds.itemWrapper} className={style.itemWrapper}>
        <LinkWrapper label={label} link={link} onClick={toggleSubMenu} />
        <button
          {...toggleAriaProps}
          aria-label={label}
          className={style.arrowWrapper}
          data-testid={TestIds.toggle}
          onClick={toggleSubMenu}
        >
          <div className={style.arrow}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 9.2828 4.89817"
            >
              <path d="M4.64116,4.89817a.5001.5001,0,0,1-.34277-.13574L.15727.86448A.50018.50018,0,0,1,.84282.136L4.64116,3.71165,8.44.136a.50018.50018,0,0,1,.68555.72852L4.98393,4.76243A.5001.5001,0,0,1,4.64116,4.89817Z" />
            </svg>
          </div>
        </button>
      </div>
      <ul className={style.subMenu}>
        {items.map((item, i) => (
          <ExpandableMenuItem
            key={i}
            {...item}
            idPrefix={`${idPrefix}-${i}`}
            skin={skin}
          />
        ))}
      </ul>
    </li>
  );
};

const ExpandableMenuItem: React.FC<ExpandableMenuItemCompProps> = props =>
  props.items.length ? SubMenuItem(props) : SingleItem(props);

export default ExpandableMenuItem;
